function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { push, replace } from 'react-router-redux';
import { DEMO_INVENTORY_WARNING_POPUP, FINISH_SWAG_BUNDLE_POPUP, SWAG_BUNDLE_ADDONS_SUCCESSFULLY_ORDERED_POPUP, SWAG_BUNDLE_CONFIRMATION_LEAVE_POPUP, SWAG_BUNDLE_DELETE_CONFIRMATION_POPUP, SWAG_BUNDLE_DELETE_NOT_ALLOWED_SINCE_ACTIVITY_POPUP, SWAG_BUNDLE_DELETE_NOT_ALLOWED_SINCE_PACKAGING_POPUP, SWAG_BUNDLE_SHARED_PRODUCT_POPUP } from 'swag-client-common/constants';
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { forcedPath } from 'swag-common/business-logic/redirect-wrapper-state';
import { getNextOrPrevNotSkippedStepForSwagBundles, getPrevSwagBundleStepBasedOnCurrent } from 'swag-common/business-logic/swag-bundles/flow/swag-bundles-steps-flow';
import { nonInventoryUrls } from 'swag-common/constants/giveaway.constants';
import { SwagBundlesStep } from 'swag-common/constants/swag-bundles.constants';
import { InventoryUrl } from 'swag-common/utils/urls/inventory.url';
import { formatCmyk } from 'swag-common/utils/cmyk.util';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { hideLoader, showLoader } from 'swag-client-common/redux/common/common.actions';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { DemoInventoryWarningTypes } from 'swag-common/constants/chameleon-tours';
import { USER_ERROR_ACTIONS } from 'swag-common/constants/user-actions';
import { decompressCustomProducts } from 'swag-common/utils/custom-product/decompress-custom-products.util';
import { findPackagingInBundleContent } from 'swag-common/utils/custom-store-product/product-is-bundle.util';
import { SwagBundlesApi } from '../../../api/swag-bundles.api';
import { scrollWindowToTopAction, windowChangeHref } from '../../../middleware/window-manipulations.middleware';
import { validateBundleCreation } from '../../../utils/validate-bundle-creation.utils';
import { getInventoryCredits, resetCreditsMoreToBuyFlow } from '../../inventory/inventory.actions';
import { isCreditsMoreToBuyFlowSelector, isSelectedDemoInventorySelector, paymentMethodSelector, selectedInventorySelector } from '../../inventory/inventory.selectors';
import { preparePaymentToInventoryProceed } from '../../payment-methods/payment-methods.actions';
import { inventoryPaymentBillingFormSelector, isManualPayment, paymentMethodIdSelector } from '../../payment-methods/payment-methods.selectors';
import { deselectAllProducts, selectProductToManage, toggleSelectionProduct } from '../../products/products.actions';
import { isProductInGiveawaySelector, productsSelector, selectedProductsAlreadyInBundlesSelector, selectedProductsIdsSelector, singleProductSelector } from '../../products/products.selectors';
import { STRIPE_FORM_SUBMIT_ERROR, STRIPE_FORM_SUBMIT_SUCCESS } from '../../stripe-form/stripe-form.actions';
import { SwagBundlesNotecardTextContentSelector, swagBundleNotecardDataSelector, swagBundlesIsNotecardSelectedToCreateSelector } from '../selectors/swag-bundles-notecard-data.selectors';
import { swagBundlesSelectedCustomProductIdsWithQuantitySelector } from '../selectors/swag-bundles-selected-variants-with-quantity.selectors';
import { notecardDataSelector, notecardQuantitySelector, packageDataSelector, packageQuantitySelector, totalAddonsCostSelector } from '../selectors/swag-bundles-shipping-data.selectors';
import { SWAG_BUNDLE_ADD_ONS_STEPS } from '../swag-bundles.constants';
import { isBundlePackagingAndNotecardPreselectedSelector, isPackagingSelectedSelector, swagBundleIsPackagingSelectedToCreateSelector, swagBundlesActiveStepSelector, swagBundlesEditingNameSelector, swagBundlesPackageDataSelector, swagBundlesPassedStepsSelector, swagBundlesSelectedNotecardsSelector, swagBundlesSelectedPackagesSelector, swagBundlesSkippedStepsSelector } from '../swag-bundles.selectors';
import { swagBundlesChangeStep } from './swag-bundles-add-ons.actions';
import { clearShippingCost } from './swag-bundles-calculate-shipping.actions';
import { SWAG_BUNDLES_ADMIN_PREFIX, resetSwagBundles, skipSwagBundlesStep } from './swag-bundles-common.actions';
import { populateSelectedProductsFromInventory } from './swag-bundles-products.actions';
export const SWAG_BUNDLES_NEXT_STEP = `${SWAG_BUNDLES_ADMIN_PREFIX}_NEXT_STEP`;
export const SWAG_BUNDLES_PASSED_STEPS = `${SWAG_BUNDLES_ADMIN_PREFIX}_PASSED_STEP`;
export const ADD_SWAG_BUNDLES_PASSED_STEP = `${SWAG_BUNDLES_ADMIN_PREFIX}_ADD_PASSED_STEP`;
export const REMOVE_SWAG_BUNDLES_PASSED_STEP = `${SWAG_BUNDLES_ADMIN_PREFIX}_REMOVE_NEXT_STEP`;
export const CHANGE_SWAG_BUNDLES_NAME = `${SWAG_BUNDLES_ADMIN_PREFIX}_CHANGE_SWAG_BUNDLES_NAME`;
export const changeSwagBundlesNameAction = name => ({
  type: CHANGE_SWAG_BUNDLES_NAME,
  payload: name
});
export const exitSuccessAction = (route, onClick) => dispatch => {
  dispatch(resetSwagBundles());
  dispatch(deselectAllProducts());
  if (route) {
    if (nonInventoryUrls.some(item => item === route)) {
      dispatch(windowChangeHref(route));
    } else {
      dispatch(replace(forcedPath(route)));
    }
  }
  onClick && onClick();
  dispatch(scrollWindowToTopAction());
  dispatch(hidePopup());
};
export const exitToInventoryAction = () => (dispatch, getState) => {
  const state = getState();
  const inventoryId = selectedInventorySelector(state);
  const routeToNavigate = `/inventory/${inventoryId}`;
  dispatch(showPopup({
    popup: SWAG_BUNDLE_CONFIRMATION_LEAVE_POPUP,
    route: routeToNavigate
  }));
};
export const goToCreateSwagBundlesMainPage = ({
  productId,
  isSelectProductsSkipped
}) => {
  return (dispatch, getState) => {
    dispatch(resetSwagBundles());
    const selectedProductsIds = selectedProductsIdsSelector(getState());
    if (isSelectProductsSkipped) {
      if (productId) {
        dispatch(toggleSelectionProduct({
          productId,
          isSelected: true
        }));
        if (!selectedProductsIds.includes(productId)) {
          selectedProductsIds.push(productId);
        }
      }
      const allProducts = productsSelector(getState());
      const selectedCustomProducts = allProducts.filter(product => selectedProductsIds.includes(product._id));
      const validationResult = validateBundleCreation(selectedCustomProducts);
      if (validationResult !== null && validationResult !== void 0 && validationResult.error) {
        return dispatch(showNotification({
          text: validationResult.error
        }));
      }
      dispatch(skipSwagBundlesStep(SwagBundlesStep.selectProducts));
      dispatch(populateSelectedProductsFromInventory(selectedProductsIds));
    } else {
      dispatch(deselectAllProducts());
    }
    const inventoryId = selectedInventorySelector(getState());
    if (!inventoryId) {
      return;
    }
    dispatch(push(InventoryUrl.getCreateSwagBundle(inventoryId)));
  };
};
export const nextSwagBundlesStepAction = activeStep => (dispatch, getState) => {
  const state = getState();
  const skippedSteps = swagBundlesSkippedStepsSelector(state);
  const currentStep = swagBundlesActiveStepSelector(state);
  let passedSteps = swagBundlesPassedStepsSelector(state);
  const isAddonsPreselected = isBundlePackagingAndNotecardPreselectedSelector(state);
  const isPackagingPreselected = isPackagingSelectedSelector(state);
  if (passedSteps.includes(SwagBundlesStep.selectProducts) && isAddonsPreselected) {
    dispatch(showPopup({
      popup: FINISH_SWAG_BUNDLE_POPUP
    }));
    return;
  } else if (activeStep === SwagBundlesStep.selectAddOns) {
    if (isPackagingPreselected) {
      dispatch(swagBundlesChangeStep(SWAG_BUNDLE_ADD_ONS_STEPS.NOTECARD_SELECTION, true));
    } else {
      dispatch(swagBundlesChangeStep(SWAG_BUNDLE_ADD_ONS_STEPS.BOX_TYPE_SELECTION, true));
    }
  }
  if (skippedSteps.includes(activeStep)) {
    activeStep = getNextOrPrevNotSkippedStepForSwagBundles(skippedSteps, activeStep);
    passedSteps = [...new Set([...passedSteps, currentStep, ...skippedSteps])];
  } else {
    passedSteps = [...passedSteps, swagBundlesActiveStepSelector(state)];
  }
  const payload = {
    activeStep,
    passedSteps
  };
  dispatch({
    type: SWAG_BUNDLES_NEXT_STEP,
    payload
  });
};
export const prevSwagBundlesStepAction = () => (dispatch, getState) => {
  const state = getState();
  const skippedSteps = swagBundlesSkippedStepsSelector(state);
  let passedSteps = [...swagBundlesPassedStepsSelector(state)];
  let prevStep = passedSteps.splice(-1)[0];
  if (skippedSteps.includes(prevStep)) {
    prevStep = getNextOrPrevNotSkippedStepForSwagBundles(skippedSteps, prevStep, getPrevSwagBundleStepBasedOnCurrent);
    const prevStepIndex = passedSteps.findIndex(passedStep => passedStep === prevStep);
    passedSteps = prevStepIndex >= 0 ? passedSteps.slice(0, prevStepIndex) : [];
  }
  if (prevStep === SwagBundlesStep.selectAddOns) {
    dispatch(clearShippingCost({
      clearNotecard: true
    }));
  }
  const payload = {
    activeStep: prevStep,
    passedSteps
  };
  dispatch({
    type: SWAG_BUNDLES_NEXT_STEP,
    payload
  });
};
export const proceedSwagBundles = createAsyncAction({
  transformData: ({
    stripeToken,
    savedCard,
    withPayment
  } = {
    stripeToken: null,
    withPayment: false
  }, getState) => {
    const state = getState();
    const inventoryId = selectedInventorySelector(state) || '';
    const swagBundleName = swagBundlesEditingNameSelector(state);
    const notecardData = createNotecardData(state);
    const packageData = createPackageData(state);
    const customProducts = getCustomProducts(state);
    const commonBody = {
      inventoryId,
      swagBundleName,
      notecardData,
      packageData,
      customProducts
    };
    if (withPayment) {
      return addPaymentData(commonBody, state, stripeToken, savedCard);
    }
    return commonBody;
  },
  fn: SwagBundlesApi.proceed,
  preFn: dispatch => {
    dispatch(showLoader());
  },
  success: (response, body, _, params) => (dispatch, getState) => {
    const withPayment = params === null || params === void 0 ? void 0 : params.withPayment;
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    dispatch(getInventoryCredits({
      inventoryId
    }));
    const isCreditsToBuyMoreFlow = isCreditsMoreToBuyFlowSelector(getState());
    if (isCreditsToBuyMoreFlow) {
      dispatch(resetCreditsMoreToBuyFlow());
    }
    if (withPayment) {
      dispatch({
        type: STRIPE_FORM_SUBMIT_SUCCESS
      });
      dispatch(showPopup({
        popup: SWAG_BUNDLE_ADDONS_SUCCESSFULLY_ORDERED_POPUP
      }));
      return;
    }
    dispatch(exitSuccessAction(`${APP_ROUTES.INVENTORY}/${inventoryId}`));
  },
  error: error => {
    var _error$message;
    return showNotification({
      text: (error === null || error === void 0 ? void 0 : (_error$message = error.message) === null || _error$message === void 0 ? void 0 : _error$message.replace('Error: ', '')) || USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
    });
  },
  postError: (dispatch, _, error) => {
    dispatch(hideLoader());
    dispatch({
      type: STRIPE_FORM_SUBMIT_ERROR,
      error: error === null || error === void 0 ? void 0 : error.message
    });
  },
  postSuccess: dispatch => {
    dispatch(hideLoader());
  }
});
export const onCloseSuccessOrderedAddons = () => (dispatch, getState) => {
  const state = getState();
  const inventoryId = selectedInventorySelector(state) || '';
  dispatch(exitSuccessAction(`${APP_ROUTES.INVENTORY}/${inventoryId}`));
};
export function createNotecardData(state) {
  const preSelectedNotecards = swagBundlesSelectedNotecardsSelector(state);
  const isNotecardSelectedToCreate = swagBundlesIsNotecardSelectedToCreateSelector(state);
  const notecardShippingData = notecardDataSelector(state);
  if (!preSelectedNotecards.length && isNotecardSelectedToCreate && notecardShippingData) {
    const {
      notecardDefaultImage,
      selectedNotecardColor,
      selectedNotecardLogo
    } = swagBundleNotecardDataSelector(state);
    const textContent = SwagBundlesNotecardTextContentSelector(state);
    const quantity = notecardQuantitySelector(state);
    return {
      quantity,
      defaultImage: notecardDefaultImage,
      color: selectedNotecardColor,
      logo: selectedNotecardLogo,
      textContent,
      serviceCode: notecardShippingData.serviceCode
    };
  }
  return null;
}
export function createPackageData(state) {
  const preSelectedPackages = swagBundlesSelectedPackagesSelector(state);
  const isPackagingSelectedToCreate = swagBundleIsPackagingSelectedToCreateSelector(state);
  const selectedPackagingData = swagBundlesPackageDataSelector(state);
  const packageShippingData = packageDataSelector(state);
  if (!preSelectedPackages.length && selectedPackagingData && isPackagingSelectedToCreate && selectedPackagingData.selectedBox && packageShippingData) {
    const {
      boxCrinklePaperColor,
      boxNote,
      selectedBoxLogo,
      selectedBoxColor,
      selectedBox,
      logos
    } = selectedPackagingData;
    const quantity = packageQuantitySelector(state);
    const color = selectedBoxColor ? typeof selectedBoxColor === 'string' ? selectedBoxColor : formatCmyk(selectedBoxColor) : '';
    return {
      color,
      crinklePaperColor: boxCrinklePaperColor,
      logo: selectedBoxLogo,
      specialProductType: selectedBox,
      boxNote,
      quantity,
      serviceCode: packageShippingData.serviceCode,
      logos
    };
  }
  return null;
}
function getCustomProducts(state) {
  const preSelectedNotecards = swagBundlesSelectedNotecardsSelector(state);
  const preSelectedPackages = swagBundlesSelectedPackagesSelector(state);
  const selectedCustomProducts = swagBundlesSelectedCustomProductIdsWithQuantitySelector(state);
  const allProducts = productsSelector(state);
  const preselectedAddons = [...preSelectedNotecards.map(notecardId => {
    var _allProducts$find, _allProducts$find$var;
    return {
      customProductId: String((_allProducts$find = allProducts.find(product => product._id === notecardId)) === null || _allProducts$find === void 0 ? void 0 : (_allProducts$find$var = _allProducts$find.variants[0]) === null || _allProducts$find$var === void 0 ? void 0 : _allProducts$find$var._id),
      quantity: 1
    };
  }), ...preSelectedPackages.map(packageId => {
    var _allProducts$find2, _allProducts$find2$va;
    return {
      customProductId: String((_allProducts$find2 = allProducts.find(product => product._id === packageId)) === null || _allProducts$find2 === void 0 ? void 0 : (_allProducts$find2$va = _allProducts$find2.variants[0]) === null || _allProducts$find2$va === void 0 ? void 0 : _allProducts$find2$va._id),
      quantity: 1
    };
  })];
  return [...preselectedAddons, ...selectedCustomProducts];
}
export function addPaymentData(commonBody, state, stripeToken, savedCard) {
  const paymentMethod = paymentMethodSelector(state);
  const expectedTotal = Math.round(totalAddonsCostSelector(state));
  const bodyWithPayment = _objectSpread({}, commonBody, {
    paymentMethod,
    expectedTotal
  });
  if (savedCard) {
    const cardId = paymentMethodIdSelector(state);
    return _objectSpread({}, bodyWithPayment, {
      cardId
    });
  }
  const form = inventoryPaymentBillingFormSelector(state);
  if (form) {
    const {
      savePaymentDetails = false,
      firstName,
      lastName
    } = form.values;
    return _objectSpread({}, bodyWithPayment, {
      stripeToken,
      paymentData: {
        firstName,
        lastName,
        savePaymentDetails
      }
    });
  }
  return _objectSpread({}, bodyWithPayment, {
    stripeToken
  });
}
export const handleSwagBundlesPaymentForAddonsSubmit = (stripe, card) => async (dispatch, getState) => {
  const state = getState();
  const isManualCard = isManualPayment(state);
  const isDemo = isSelectedDemoInventorySelector(state);
  if (isDemo) {
    return dispatch(showPopup({
      popup: DEMO_INVENTORY_WARNING_POPUP,
      warningType: DemoInventoryWarningTypes.GiveawayPayment
    }));
  }
  if (isManualCard) {
    return dispatch(preparePaymentToInventoryProceed(stripe, card, token => {
      dispatch(proceedSwagBundles({
        stripeToken: token.id,
        withPayment: true
      }));
    }));
  }
  dispatch(proceedSwagBundles({
    savedCard: true,
    withPayment: true
  }));
};
export const DELETE_SWAG_BUNDLE_STARTED = `${SWAG_BUNDLES_ADMIN_PREFIX}_DELETE_STARTED`;
export const DELETE_SWAG_BUNDLE_SUCCESS = `${SWAG_BUNDLES_ADMIN_PREFIX}_DELETE_SUCCESS`;
export const DELETE_SWAG_BUNDLE_FAILED = `${SWAG_BUNDLES_ADMIN_PREFIX}_DELETE_FAILED`;
export const deleteSwagBundleAction = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: DELETE_SWAG_BUNDLE_STARTED
    });
    dispatch(showLoader());
  },
  transformData: (_, getState) => {
    const state = getState();
    return {
      inventoryId: state.inventory.selectedInventory,
      productId: state.products.productIdToManage
    };
  },
  fn: SwagBundlesApi.delete,
  success: ({
    products,
    swagProducts,
    psProducts,
    sizeSettings
  }) => {
    const decompressedProducts = decompressCustomProducts(products, swagProducts, psProducts, sizeSettings);
    return {
      type: DELETE_SWAG_BUNDLE_SUCCESS,
      payload: decompressedProducts
    };
  },
  error: errorAction(DELETE_SWAG_BUNDLE_FAILED),
  postSuccess: dispatch => {
    dispatch(hidePopup());
    dispatch(hideLoader());
  },
  postError: dispatch => {
    dispatch(hideLoader());
  }
});
export const deleteSwagBundle = productId => (dispatch, getState) => {
  var _packaging$product, _packaging$product2;
  const state = getState();
  const product = singleProductSelector(state, {
    productId
  });
  const productInGiveaway = isProductInGiveawaySelector(state, {
    productId
  });
  const bundle = product === null || product === void 0 ? void 0 : product.variants[0];
  const bundleContent = (bundle === null || bundle === void 0 ? void 0 : bundle.bundleContent) || [];
  const bundleHasReserved = bundleContent.some(v => {
    var _v$product;
    return ((_v$product = v.product) === null || _v$product === void 0 ? void 0 : _v$product.inReserveBundleQuantity) > 0;
  });
  const isBundleInActivities = productInGiveaway || bundleHasReserved;
  if (isBundleInActivities) {
    dispatch(showPopup({
      popup: SWAG_BUNDLE_DELETE_NOT_ALLOWED_SINCE_ACTIVITY_POPUP
    }));
    return;
  }
  const packaging = findPackagingInBundleContent(bundle === null || bundle === void 0 ? void 0 : bundle.bundleContent);
  if (packaging && ((_packaging$product = packaging.product) !== null && _packaging$product !== void 0 && _packaging$product.inStockQuantity || packaging !== null && packaging !== void 0 && (_packaging$product2 = packaging.product) !== null && _packaging$product2 !== void 0 && _packaging$product2.inProductionQuantity)) {
    dispatch(showPopup({
      popup: SWAG_BUNDLE_DELETE_NOT_ALLOWED_SINCE_PACKAGING_POPUP
    }));
    return;
  }
  dispatch(showPopup({
    popup: SWAG_BUNDLE_DELETE_CONFIRMATION_POPUP
  }));
  dispatch(selectProductToManage(productId));
};
export const checkSelectedProductsForBundle = nextStep => (dispatch, getState) => {
  const state = getState();
  const sharedSelectedProducts = selectedProductsAlreadyInBundlesSelector(state);
  if (sharedSelectedProducts.length) {
    dispatch(showPopup({
      popup: SWAG_BUNDLE_SHARED_PRODUCT_POPUP,
      nextStep
    }));
    return;
  }
  nextStep();
};